import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import Branch from "@/models/branch";

@Module({ namespaced: true })
class BranchesModule extends VuexModule {
  branches: Branch[] | null = null;

  public get branchesList(): Branch[] {
    return Array.isArray(this.branches) ? this.branches : [];
  }

  public get branchesListFormatted() {
    return Array.isArray(this.branches) ? this.branches.map((branch => branch.full_name)) : [];
  }

  @Mutation
  setBranches(branches: Branch[]) {
    this.branches = branches;
  }
}

export default BranchesModule;
