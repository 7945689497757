import { isDateString } from "@/lib/date";

const checkbox = {
  type: "checked",
  error: "Отметьте данный чекбокс",
  validator: (v: boolean): boolean => v
};

const date = {
  type: "date",
  error: "Значение поля не соответствует формату даты",
  validator: (v: string): boolean => {
    return isDateString(v) && (/^\d{2}\.\d{4}$/.test(v) || /^\d{2}\.\d{2}\.\d{4}$/.test(v));
  }
};

const digit = {
  type: "digit",
  error: "Значение поля должно содержать только цифры",
  validator: (v: string): boolean => /^\d+$/.test(v)
};

const digitFloat = {
  type: "digitFloat",
  error: "Значение поля должно содержать цифры",
  validator: (v: string): boolean => /^\d+(?:\.\d{1,6})?$/.test(v)
};

const digitFloatOrEmpty = {
  type: "text",
  error: "Неправильно заполнено поле",
  validator: (v: string): boolean => {
    if (v === "") return true;
    let vReplaced = v;
    vReplaced = vReplaced.replace(/,/, '.');
    return /^\d+(?:\.\d{1,6})?$/.test(vReplaced);
  }
};

const amount = {
  type: "text",
  error: "Значение должно быть больше нуля",
  validator: (v: string): boolean => parseFloat(v) > 0
};

const email = {
  type: "email",
  error: "Значение поля не соответствует формату email"
};

const phone = {
  type: "phone",
  error: "Значение поля не соответствует формату телефонного номера",
  validator: (v: string): boolean => {
    const endRegExp = "([- ]?\\d{2}){2}$";
    const phonePatterns = [
      new RegExp("^(\\d{4}|\\(\\d{4}\\))[- ]?\\d{2}" + endRegExp),
      new RegExp("^(\\d{3}|\\(\\d{3}\\))[- ]?\\d{3}" + endRegExp)
    ];
    return phonePatterns.some(pattern => pattern.test(v));
  }
};

const required = {
  type: "required",
  error: "Заполните данное поле"
};

const passwordCompare = {
  type: "passwordCompare",
  error: "Значение паролей не совпадают",
  validator: (v: string, vm: { [x: string]: string }): boolean => {
    return v === vm.newPwd;
  }
};

// валидация пароля в зависимости от указанной сложности
const type = "NNNN6";
const types = type.split("");
let length = 0;
const val = parseInt(types[4]);
if (val > 0) {
  length = val;
}
const passwordStrength1 = {
  type: "passwordStrength1",
  error: "Пароль должен содержать строчные буквы",
  validator: (v) => types[0] != "Y" || (new RegExp("[a-z]+")).test(v)
}
const passwordStrength2 = {
  type: "passwordStrength2",
  error: "Пароль должен содержать заглавные буквы",
  validator: (v) => types[1] != "Y" || (new RegExp("[A-Z]+")).test(v)
}
const passwordStrength3 = {
  type: "passwordStrength3",
  error: "Пароль должен содержать цифры",
  validator: (v) => types[2] != "Y" || (new RegExp("[0-9]+")).test(v)
}
const passwordStrength4 = {
  type: "passwordStrength4",
  error: "Пароль должен содержать спецсимволы",
  validator: (v) => types[3] != "Y" || (new RegExp("[`~!@#$%^&\\*\\(\\)_\\-\\+=№;%:\\?\\[\\]\\{\\}\\\\\|\\/'\":;.,<>]+")).test(v)
}

const passwordLength = {
  type: "passwordLength",
  error: "Пароль должен быть более " + length + " символов",
  validator: (v) => v.length >= length
}

export {
  checkbox,
  date,
  digit,
  digitFloat,
  digitFloatOrEmpty,
  amount,
  email,
  phone,
  required,
  passwordCompare,
  passwordStrength1,
  passwordStrength2,
  passwordStrength3,
  passwordStrength4,
  passwordLength,
};
