















































































import {Component, Mixins, Prop} from "vue-property-decorator";
import {validationMixin} from "vuelidate";
import XForm from "../SimpleForm.vue";
import XButton from "../SimpleButton.vue";
import XDialog from "../SimpleDialog.vue";
import AppApiMixin from "../mixins/AppApi.vue";
import {countersNewValueForm} from "@/assets/scripts/form-fields/main";
import XNotice from "@/components/hoc/SimpleNotice.vue";
import {mapMutations, mapState} from "vuex";
import { Indication } from "@/models/indications";

const { values, attrs, validations, errors } = countersNewValueForm;

@Component({
  components: {
    XForm,
    XButton,
    XDialog,
    XNotice
  },
  mixins: [validationMixin],
  computed: {
    ...errors,
    ...mapState({
      accountingPointsPU: "accountingPoints",
    }),
  },
  methods: {
    ...mapMutations("error", { setShow: "setShow", setMessage: "setMessage" }),
  },
  validations,
})
class CountersNewValueForm extends Mixins(XForm, AppApiMixin) {
  @Prop({ required: true }) readonly rate!: number;
  @Prop({ required: true }) readonly rateFloat!: number;
  @Prop({ required: true }) readonly meteringPointId!: number;
  @Prop({ required: true }) readonly counterId!: number;
  @Prop({ required: true }) readonly zones!: number;
  @Prop({ required: true }) readonly disabled!: boolean;

  values = { ...values };

  invalidRateFlag = true;
  invalidRate = [ true, true, true ];
  rateFlag = [ false, false, false ];

  showDialog = false;

  useSuccessAlert = false;
  successMessage = "Показания успешно переданы на обработку";

  get indications() {
    return this.accountingPointsPU.accountingPointsPU.filter((item) => {
      return item.счетчикид == this.counterId;
    });
  }

  showField(fieldName) {
    return this.indications.hasOwnProperty(fieldName);
  }

  indication(fieldName) {
    return this.indications[fieldName].предпок;
  }

  indicationPrev(fieldName) {
    return this.indications[fieldName].предпок.toFixed(this.rateFloat);
  }

  zone(fieldName) {
    return this.indications[fieldName].зонатарифа;
  }

  getMask(str: string) {
    let r = "".padStart(this.rate, str);
    let rf = this.rateFloat > 0 ? "." + "".padStart(this.rateFloat, str) : "";
    return "" + r + rf;
  }

  fieldRequired(fieldName: number) {
    return fieldName < this.zones
  }

  // меняем флаг в функции, т.к. computed не отрабатывает на массив
  changeRate(fieldName, value) {
    this.invalidRate[fieldName] = value;
    this.invalidRateFlag = this.invalidRate.filter((item, key) => item == true && key < this.zones).length > 0;
  }

  get rateValidator() {
    const { rate, rateFloat } = this;

    const pattern = {
      template: `^\\d{1,${rate}}*$`,
      replacer: rateFloat > 0 ? `([.,]\\d{1,${rateFloat}})?` : "",
    };

    return new RegExp(pattern.template.replace("*", pattern.replacer));
  }

  public validateRate(fieldName: number, readings: string) {
    this.rateFlag[fieldName] = false;

    if (!readings) {
      // не указано значение, форма невалидна, но не взводим ошибку на поле ввода
      this.changeRate(fieldName, true);
      return true;
    }

    if (!this.rateValidator.test(readings)) {
      // поле невалидно
      this.changeRate(fieldName, true);
      return false;
    }

    let currentIndication = readings;
    currentIndication = currentIndication.replace(/,/, '.');
    const readingsAsNumber = parseFloat(currentIndication);

    if (this.indication(fieldName) > readingsAsNumber && readingsAsNumber > 0) {
      // показание меньше предыдущего
      this.rateFlag[fieldName] = true;
    }

    this.changeRate(fieldName, false);

    this.submitError = "";

    return true;
  }

  allFieldAttrs = attrs;

  useSubmitButtonLoading = false;
  submitError = "";

  resetData(){
    // this.$refs.simpleForm.reset();
    // сброс значений показаний
    let { values } = this;
    Object.keys(values).forEach(function(key, index) {
      values[key] = "";
    });
    this.$v.$reset();
  }

  showSuccessInfo(){
    this.useSuccessAlert = true;
  }

  submitForm() {
    this.submitError = "";

    // проверка взведенных флагов показаний по перекрутке
    if (this.rateFlag.filter((item, key) => item == true && key < this.zones).length > 0) {
      this.setMessage("Показание меньше предыдущего");
      this.setShow(true);
    } else {
      this.showDialog = true;
    }
  }

  sendData() {
    const values = this.getValues();

    let indications: Array<Indication> = [];
    for (let i = 0; i < this.zones; i++) {
      const currentIndication = values[i].replace(/,/, '.');
      const readingsAsNumber = parseFloat(currentIndication);
      indications.push({
        rate: i,
        readings: readingsAsNumber,
        twisting: 0,
      });
    }

    this.useSubmitButtonLoading = true;
    this.sendCountersNewValue(
      this.meteringPointId,
      this.counterId,
      indications
    )
      .then(() => {
        this.resetData();
        this.showSuccessInfo();
        // перечитываем показания для отрисовки
        this.fetchAccountingPointsPU(this.contractId);
      })
      .catch(() => {
        this.resetData();
      })
      .finally(() => {
        this.useSubmitButtonLoading = false;
      });
  }
}

export default CountersNewValueForm;
