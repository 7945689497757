






























































import { Component, Vue } from "vue-property-decorator";
import CardAsAlert from "@/components/hoc/CardAsAlert.component.vue";
import ConfirmActionDialog from "@/components/hoc/ConfirmActionDialog.vue";
import Page from "@/components/for-page-grid/Page.vue";
import ReplacementCard from "@/components/for-account-settings/ReplacementCard.vue";
import XCard from "@/components/SimpleCard.vue";
import XNotice from "@/components/hoc/SimpleNotice.vue";
import { mapGetters } from "vuex";

@Component({
  components: {
    CardAsAlert,
    ConfirmActionDialog,
    Page,
    ReplacementCard,
    XCard,
    XNotice,
  },
  computed: { ...mapGetters("user", { useWarning: "passwordChangeRequired" }) },
})
class AccountSettings extends Vue {
  notice = {
    show: false,
    message: "",
  };

  actionDialog = {
    show: false,
    payload: "",
  };

  public closeAllDialogs() {
    this.actionDialog = {
      show: false,
      payload: "",
    };
  }
}

export default AccountSettings;
