import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import Config from "@/models/config";

@Module({ namespaced: true })
class ConfigModule extends VuexModule {
  config: Config | null = null;

  @Mutation
  setConfig(config: Config) {
    this.config = config;
  }
}

export default ConfigModule;
