
import { Component, Vue } from "vue-property-decorator";
import {mapGetters, mapMutations, mapState} from "vuex";
import {
  AppealsByContractAsReqBody,
  CompanyAsNewUser,
  ConsumptionAsReqBody,
  Credentials,
  Recovery,
  DocumentsAsReqBody,
  ContractDocumentsAsReqBody,
  NewEmailProps,
  PasswordsAsReqBody,
  ReportAsReqBody,
  SetOfDocumentsAsReqBody,
  GetContractDocumentAsReqBody,
  Turnovers,
  TurnoversDetails,
  AppealHistoryRefreshReq,
  AppealHistoryFilesRefreshReq,
  AppealHistoryFileInfoRefreshReq,
  AppealCustomFieldsReq,
} from "@/models/app-api";
import { Contract } from "@/models/contract";
import { AccountingPoint, DeviceForHistory } from "@/models/accounting-point";
import {
  AppealTypes,
  AppealThemes,
} from "@/models/appeals";
import appApi from "@/assets/scripts/app-api";
import { Indication } from "@/models/indications";

@Component({
  computed: {
    ...mapGetters({
      sortedContractsByOrganization: "contracts/sortedContractsByOrganization",
      currentContractId: "contract/id",
      unreadAppealsCount: "appeals/unreadAppealsCount"
    }),
    ...mapGetters("user", {
      passwordChangeRequired: "passwordChangeRequired",
      userId: "id",
    }),
    ...mapGetters({
      notificationsList: "notifications/notificationsList",
      unreadNotificationsCount: "notifications/unreadNotificationsCount",
    }),
    ...mapState({ appEnvironment: "appEnvironment" }),
  },
  methods: {
    ...mapMutations("contracts", {
      setContracts: "setContracts",
      setBalances: "setBalances",
    }),
    ...mapMutations("contract", {
      setContract: "setContract",
    }),
    ...mapMutations("accountingPoints", {
      setAccountingPoints: "setPoints",
      setAccountingHistoryPoints: "setHistoryPoints",
      setAccountingPointsPU: "setPointsPU",
      setAccountingPointsMeasures: "setPointsMeasures",
    }),
    ...mapMutations("accountingPoint", {
      setPoint: "setPoint",
      setDevice: "setDevice",
      setDeviceHistory: "setDeviceHistory",
      setPointMeasuringComplex: "setMeasuringComplex",
      setPointParams: "setParams",
      setPointPlanServices: "setPlanServices",
      setPointPlanValues: "setPlanValues",
      setPointHistory: "setPointHistory",
      setPointOtherEquipment: "setOtherEquipment",
    }),
    ...mapMutations("hourlyConsumption", {
      setAskueObjects: "setAskueObjects",
      setPointHourlyPlan: "setPointHourlyPlan",
      setPointHourlyFact: "setPointHourlyFact",
    }),
    ...mapMutations("asd", {
      setAsdObjects: "setAsdObjects",
    }),
    ...mapMutations("organization", {
      setOrganization: "setOrganization",
      setContacts: "setContacts",
      setPaymentAccounts: "setPaymentAccounts",
      setAgents: "setAgents",
    }),
    ...mapMutations("error", {
      setShowError: "setShow",
      setErrorMessage: "setMessage",
    }),
    ...mapMutations({
      setUser: "user/setUser",
      setLoggedIn: "auth/setLoggedIn",
      setappEnvironment: "appEnvironment/setConstants",
      setPreloader: "preloader/setShow",
      setCounterValue: "sidebar/setCounterValue",
      setBranches: "branches/setBranches",
      setConfig: "config/setConfig",
    }),
    ...mapMutations("appeals", {
      setAppealsByContract: "setAppealsByContract",
      setTypes: "setTypes",
      setCustomFields: "setCustomFields",
      setThemes: "setThemes",
      setAppealHistory: "setAppealHistory",
      setUnreadCounterValue: "setUnreadCounterValue",
      setCurrentAppealInfo: "setCurrentAppealInfo",
    }),
    ...mapMutations("notifications", {
      setNotificationsByContract: "setNotificationsByContract",
      setUnreadNotificationsByContract: "setUnreadNotificationsByContract",
    }),
  },
})
class AppApiMixin extends Vue {
  [x: string]: any;

  mounted() {}

  async getBranches() {
    try {
      const { data } = await appApi.fetchBranches();
      this.setBranches(data);
      return true;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getConfig() {
    try {
      const { data } = await appApi.fetchConfig();
      this.setConfig(data);
      return true;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getAppEnvironment() {
    try {
      const { data } = await appApi.fetchEnvironment();
      this.setappEnvironment(data);

      return true;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /*--------------------------------------------------- ОБРАЩЕНИЯ ----------------------------------------------------*/

  /**
   * Получить список обращений
   * @param appealsProps
   * @param onFulfilled
   */
  getAppealsByContract(
    appealsProps: AppealsByContractAsReqBody,
    onFulfilled: CallableFunction
  ) {
    this.setPreloader(true);
    appApi
      .fetchAppealsByContract(appealsProps)
      .then(({ data: fetchedAppeals }) => {
        this.setAppealsByContract(fetchedAppeals);
        onFulfilled(fetchedAppeals);
      })
      .catch(console.error)
      .finally(() => {
        this.setPreloader(false);
      });
  }

  async getAppealHistory(
    appealChat_getProps: AppealHistoryRefreshReq,
    onFulfilled: CallableFunction
  ) {
    this.setPreloader(true);
    appApi
      .fetchAppealHistory(appealChat_getProps)
      .then(({data: fetchedAppealHistory}) => {
        this.setAppealHistory(fetchedAppealHistory);
        onFulfilled(fetchedAppealHistory);
      })
      .catch(console.error)
      .finally(() => {
        this.setPreloader(false);
      });
  }

  async getAppealFiles(
    appealFiles_getProps: AppealHistoryFilesRefreshReq,
    onFulfilled: CallableFunction
  ) {
    this.setPreloader(true);
    appApi
      .fetchAppealFiles(appealFiles_getProps)
      .then(({data: fetchedAppealFiles}) => {
        onFulfilled(fetchedAppealFiles);
      })
      .catch(console.error)
      .finally(() => {
        this.setPreloader(false);
      });
  }

  async getHistoryAppealFileInfo(
    appealFileInfo: AppealHistoryFileInfoRefreshReq,
    onFulfilled: CallableFunction
  ) {
    this.setPreloader(true);
    appApi
      .fetchHistoryAppealFileInfo(appealFileInfo)
      .then(({data: fetchedAppealFileInfo}) => {
        onFulfilled(fetchedAppealFileInfo);
      })
      .catch(console.error)
      .finally(() => {
        this.setPreloader(false);
      });
  }

  /*----------------------------------------------- ОБРАЩЕНИЯ СОЗДАТЬ ------------------------------------------------*/

  async getAllAppealTypes(): Promise<AppealTypes[]> {
    try {
      const { data } = await appApi.fetchAppealTypes();
      this.setTypes(data);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getAllAppealThemes(): Promise<AppealThemes[]> {
    try {
      const { data } = await appApi.fetchAppealThemes();
      this.setThemes(data);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getCustomFieldsData(appealFields_getData: AppealCustomFieldsReq) {
    this.setPreloader(true);
    appApi.fetchAppealCustomFields(appealFields_getData)
      .then(({data: fetchedCustomFields}) => {
        this.setCustomFields(fetchedCustomFields);
      })
      .catch(console.error)
      .finally(() => {
        this.setPreloader(false);
      });
  }

  /*--------------------------------------------------- ????????? ----------------------------------------------------*/

  getDocumentsByContract(
    documentsProps: DocumentsAsReqBody,
    onFulfilled: CallableFunction
  ) {
    this.setPreloader(true);
    appApi
      .fetchDocumentsByContract(documentsProps)
      .then(({ data: fetchedDocuments }) => {
        onFulfilled(fetchedDocuments);
      })
      .catch(console.error)
      .finally(() => {
        this.setPreloader(false);
      });
  }

  getContractDocumentsByContract(
      contractDocumentsProps: ContractDocumentsAsReqBody,
      onFulfilled: CallableFunction
  ) {
    this.setPreloader(true);
    appApi
      .fetchContractDocumentsByContract(contractDocumentsProps)
      .then(({ data: fetchedContractDocuments }) => {
        onFulfilled(fetchedContractDocuments);
      })
      .catch(console.error)
      .finally(() => {
        this.setPreloader(false);
      });
  }

  getContractDocumentByContract(requestProps: GetContractDocumentAsReqBody) {
    return appApi
        .getContractDocumentByContract(requestProps)
        .then((axiosRes) => axiosRes)
        .catch((e) => Promise.reject(appApi.onReject(e)));
  }

  async getContracts(userId: number): Promise<Contract[]> {
    try {
      const { data } = await appApi.fetchContracts(userId);
      this.setContracts(data);

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getContractsBalances(contracts: Contract[]): Promise<boolean> {
    const contractId = contracts
      .map((contract) => contract["$номерЗаписи"])
      .join(",");

    try {
      const { data } = await appApi.fetchContractsBalances(contractId);
      this.setBalances(data);

      return true;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  fetchNotifications(contractId: number) {
    return appApi
      .fetchNotificationsByContract(contractId)
      .then(({ data: fetchedNotifications }) => {
        this.setNotificationsByContract(fetchedNotifications);
        this.setUnreadNotificationsByContract(Array.from(fetchedNotifications).filter(item =>
          item['статус'] == 2
        ).length);
      })
      .catch((e) => Promise.reject(appApi.onReject(e)));
  }

  getNotificationFiles(notificationId: number) {
    return appApi
      .getNotificationFilesByContract(notificationId)
      .then((axiosRes) => axiosRes)
      .catch((e) => Promise.reject(appApi.onReject(e)));
  }

  readNotification(
    notificationId: number,
    onFulfilled: CallableFunction
  ) {
    this.setPreloader(true);
    appApi.readNotificationByContract(notificationId)
      .then((data) => {
        onFulfilled(data);
      })
      .catch(console.error)
      .finally(() => {
        this.setPreloader(false);
      });
  }

  chooseContract(contract: Contract, isAlone = false) {
    //Если при смене договора не прочитали уведомления - запрещаем менять
    if(contract.$номерЗаписи != this.currentContractId) {
      if(this.unreadNotificationsCount > 0) {
        return;
      }
    }

    this.setPointPlanServices(null);
    const contractId = contract["$номерЗаписи"];
    const companyId = contract["организация"];

    !isAlone && this.setPreloader(true);

    return Promise.all([
      appApi.chooseContract(contractId),
      appApi.fetchAccountingPoints(contractId),
      appApi.fetchAccountingHistoryPoints(contractId),
      appApi.fetchOrganization(companyId),
      appApi.fetchContractsBalances(contractId.toString()),
      appApi.fetchNotificationsByContract(contractId)
      //appApi.fetchAppealsByContract({contractId: contractId}),
    ])
      .then(
        ([
           { data: fetchedContract },
           { data: fetchedPoints },
           { data: fetchedHistoryPoints },
           { data: fetchedCompany },
           { data: fetchedBalance },
           { data: fetchedNotifications },
         ]) => {
          this.setContract({ ...contract, ...fetchedContract[0] });
          this.setAccountingPoints(fetchedPoints);
          this.setAccountingHistoryPoints(fetchedHistoryPoints);
          this.setOrganization(fetchedCompany[0]);

          this.setNotificationsByContract(fetchedNotifications);
          let unreadCount = Array.from(fetchedNotifications).filter(item =>
            item['статус'] == 2
          ).length;
          this.setUnreadNotificationsByContract(unreadCount);

          if(this.unreadNotificationsCount > 0) {
            this.$router.push({
              name: "notificationsByContract",
              params: {contractId: contractId.toString()},
            });
          }
          else if (!isAlone) {
            if(fetchedBalance[0]['сальдо'] > 0)
              this.$router.push({
                name: "documents",
                params: {contractId: contractId.toString()},
              });
            else
              this.$router.push({
                name: "contractCard",
                params: {contractId: contractId.toString()},
              });
          }
        }
      )
      .catch(() => console.error)
      .finally(() => {
        this.setPreloader(false);
        if (this.appEnvironment.constants["ЛКОТКЛОБР"] != 1) {
          appApi.fetchAppealsByContract({contractId: contractId}).then(({data: fetchedAppeals}) => {
            if (fetchedAppeals) {
              let sorted_app = [];
              let app_p1 = Array.from(fetchedAppeals).filter(item =>
                item['непрочитано'] > 0 && item['обращение-результат>признаки'] % 4 >= 2
              );

              let app_p2 = Array.from(fetchedAppeals).filter(item =>
                item['обращение-результат>название'] == ''
              );
              let app_p3 = Array.from(fetchedAppeals).filter(item =>
                item['обращение-результат>название'] != ''
              );

              let set = new Set();

              app_p1.forEach((item) => {
                set.add(item);
              });
              app_p2.forEach((item) => {
                set.add(item);
              });
              app_p3.forEach((item) => {
                set.add(item);
              });
              set.forEach(item => sorted_app.push(item));

              this.setUnreadCounterValue(app_p1);
              this.setAppealsByContract(sorted_app);
            }
          }).catch((e) => {
            console.log(e);
            this.setErrorMessage('Центр обращений не работает. Раздел обращений будет пуст');
          });
        }
      });

  }

  removeContract(contractId: number, onFulfilled: CallableFunction) {
    return appApi
      .removeContract(contractId)
      .then((_) => {
        onFulfilled();

        if (this.currentContractId === contractId) {
          this.setContract(null);
        }

        this.enterApp();
      })
      .catch((e) => Promise.reject(e.message));
  }

  async addContract(newContract: CompanyAsNewUser) {
    try {
      return await appApi.addContract(newContract);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  chooseAccountingPoint(point: AccountingPoint) {
    const pointId = point["лицевой"];
    this.setPreloader(true);

    return Promise.all([
      appApi.fetchPointMeasuringComplex(pointId),
      appApi.fetchPointParams(pointId),
      appApi.fetchPointPlanServices(pointId),
      appApi.fetchPointPlanValues(pointId),
      appApi.fetchPointOtherEquipment(pointId),
    ])
      .then(
        ([
           { data: fetchedMeasuringComplex },
           { data: fetchedParams },
           { data: fetchedServices },
           { data: fetchedPlanValues },
           { data: fetchedOtherEquipment },
         ]) => {
          this.setPoint(point);
          this.setPointMeasuringComplex(fetchedMeasuringComplex);
          this.setPointParams(fetchedParams);
          this.setPointPlanServices(fetchedServices);
          this.setPointPlanValues(fetchedPlanValues);
          this.setPointOtherEquipment(fetchedOtherEquipment);
        }
      )
      .catch(console.error)
      .finally(() => this.setPreloader(false));
  }

  sendPlanValues(data: FormData) {
    return appApi
      .sendPlanValues(data)
      .then((res) => {
        return res.data;
      })
      .catch((reason) => {
        return Promise.reject(appApi.onReject(reason));
      });
  }

  chooseHourlyPoint(reqBody: { id: number; level: 0 | 1; month: string }) {
    return Promise.all([
      appApi.fetchHourlyPoint({ ...reqBody, mode: 0 }),
      appApi.fetchHourlyPoint({ ...reqBody, mode: 1 }),
    ])
      .then(([{ data: fetchedPlan }, { data: fetchedFact }]) => {
        this.setPointHourlyPlan(fetchedPlan);
        this.setPointHourlyFact(fetchedFact);
      })
      .catch(console.error);
  }

  getAskueObjects(contractId: number) {
    this.setPreloader(true);
    appApi
      .fetchAskueObjects(contractId)
      .then(({ data: objects }) => this.setAskueObjects(objects))
      .catch(console.error)
      .finally(() => this.setPreloader(false));
  }

  getContractPoints(contractId: number) {
    this.setPreloader(true);
    appApi
      .fetchContractPoints(contractId)
      .then(({ data: objects }) => this.setAsdObjects(objects))
      .catch(console.error)
      .finally(() => this.setPreloader(false));
  }

  downloadConsumptionDocument(mode: 0 | 1 | 2, month: string) {
    return appApi.downloadConsumptionDocument(mode, month);
  }

  uploadConsumptionDocument(reqBody: FormData) {
    return appApi.uploadConsumptionDocument(reqBody);
  }

  async fetchPlanValues(meteringPointId: number) {
    try {
      const { data } = await appApi.fetchPointPlanValues(meteringPointId);
      this.setPointPlanValues(data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  chooseDeviceForHistory(
    device: DeviceForHistory,
    onFulfilled: CallableFunction
  ) {
    this.setPreloader(true);

    appApi
      .fetchPUHistory(device['лицевой'], this.currentContractId)
      .then((fetchedHistory) => {
        const history = fetchedHistory.data.data;
        this.setDevice(device);
        this.setDeviceHistory(history);

        onFulfilled();
      })
      .catch((reason) => console.error(appApi.onReject(reason)))
      .finally(() => this.setPreloader(false));
  }

  getOrgAgents(
    organizationId: number,
    onFulfilled: CallableFunction
  ) {
    appApi.fetchOrganizationAgents(organizationId)
      .then(({ data: fetchedAgents }) => {
        this.setAgents(fetchedAgents);
        if (onFulfilled != null) {
          onFulfilled(fetchedAgents);
        }
      })
      .catch(console.error)
      .finally(() => {
        this.setPreloader(false);
      });
  }

  resetUnreadAppeal(
    appealId: number,
    onFulfilled: CallableFunction
  ) {
    this.setPreloader(true);
    appApi.sendUnreadReset(appealId)
      .then((data) => {
        onFulfilled(data);
      })
      .catch(console.error)
      .finally(() => {
        this.setPreloader(false);
      });
  }

  getAdditionalOrganizationData(organizationId: number) {
    this.setPreloader(true);

    Promise.all([
      appApi.fetchOrganizationContacts(organizationId),
      appApi.fetchOrganizationPaymentAccounts(organizationId),
      appApi.fetchOrganizationAgents(organizationId),
    ])
      .then(
        ([
           { data: fetchedContacts },
           { data: fetchedPaymentAccounts },
           { data: fetchedAgents },
         ]) => {
          this.setContacts(fetchedContacts);
          this.setPaymentAccounts(fetchedPaymentAccounts);
          this.setAgents(fetchedAgents);
        }
      )
      .catch(console.error)
      .finally(() => {
        this.setPreloader(false);
      });
  }

  getOrganizationTurnovers(
    turnoversProps: Turnovers,
    onFulfilled: CallableFunction
  ) {
    this.setPreloader(true);

    appApi
      .fetchOrganizationTurnovers(turnoversProps)
      .then(({ data }) => {
        onFulfilled(data);
      })
      .catch(console.error)
      .finally(() => {
        this.setPreloader(false);
      });
  }

  getOrganizationTurnoversDetails(
    arrayOfturnoversProps: TurnoversDetails[],
    onFulfilled: CallableFunction
  ) {
    return Promise.all(
      arrayOfturnoversProps.map((props) =>
        appApi.fetchOrganizationTurnoversDetails(props)
      )
    )
      .then((arrayOffetchedTurnoversDetails) => {
        onFulfilled(arrayOffetchedTurnoversDetails.map(({ data }) => data));
      })
      .catch(console.error);
  }

  getOrganizationReports(contractId: number, onFulfilled: CallableFunction) {
    this.setPreloader(true);

    appApi
      .fetchOrganizationReports(contractId)
      .then(({ data: fetchedReports }) => {
        onFulfilled(fetchedReports);
      })
      .catch(console.error)
      .finally(() => {
        this.setPreloader(false);
      });
  }

  async getReportByContract(reportProps: ReportAsReqBody) {
    try {
      return await appApi.getReportByContract(reportProps);
    } catch (e) {
      return Promise.reject(appApi.onReject(e));
    }
  }

  getServicesByContract(contractId: number, onFulfilled: CallableFunction) {
    this.setPreloader(true);

    appApi
      .fetchServicesByContract(contractId)
      .then(({ data: fetchedServices }) => {
        onFulfilled(fetchedServices);
      })
      .catch(console.error)
      .finally(() => {
        this.setPreloader(false);
      });
  }

  getChannelsList(
    consumptionProps,
    onFulfilled: CallableFunction
  ) {
    this.setPreloader(true);
    return appApi
      .fetchChannelsList(consumptionProps)
      .then(({ data: fetchedChannelsList }) => {
        onFulfilled(fetchedChannelsList);
      })
      .catch(console.error)
      .finally(() => {
        this.setPreloader(false);
      });
  }

  getTableAnalysis(
    consumptionProps,
    onFulfilled: CallableFunction
  ) {
    this.setPreloader(true);
    return appApi
      .fetchTableAnalysis(consumptionProps)
      .then(({ data: fetchedTableAnalysis }) => {
        onFulfilled(fetchedTableAnalysis);
      })
      .catch(console.error)
      .finally(() => {
        this.setPreloader(false);
      });
  }

  getChannels(
    consumptionProps,
    onFulfilled: CallableFunction
  ) {
    this.setPreloader(true);
    return appApi
      .fetchChannels(consumptionProps)
      .then(({ data: fetchedChannels }) => {
        onFulfilled(fetchedChannels);
      })
      .catch(console.error)
      .finally(() => {
        this.setPreloader(false);
      });
  }

  getConsumptionList(
    consumptionProps: ConsumptionAsReqBody,
    onFulfilled: CallableFunction
  ) {
    this.setPreloader(true);

    return appApi
      .fetchConsumptionList(consumptionProps)
      .then(({ data: fetchedConsumptionList }) => {
        onFulfilled(fetchedConsumptionList);
      })
      .catch(console.error)
      .finally(() => {
        this.setPreloader(false);
      });
  }

  getSetOfDocumentsByContract(documentProps: SetOfDocumentsAsReqBody) {
    return appApi
      .fetchSetOfDocumentsByContract(documentProps)
      .then((axiosRes) => axiosRes)
      .catch((e) => Promise.reject(appApi.onReject(e)));
  }

  getAnalysts(onFulfilled: CallableFunction) {
    appApi
      .fetchAnalysts()
      .then((fetchedAnalysts) => {
        const analysts = fetchedAnalysts.data.data;
        onFulfilled(analysts);
      })
      .catch((reason) => console.error(appApi.onReject(reason)));
  }

  fetchAccountingPointsPU(contractId: number) {
    appApi
      .fetchAccountingPointsPU(contractId)
      .then((fetchedPoints) => {
        const currentPoints = fetchedPoints.data.data;
        this.setAccountingPointsPU(currentPoints);
      })
      .catch((reason) => console.error(appApi.onReject(reason)))
      .finally(() => {});
  }

  fetchAccountingPointsMeasures(contractId: number) {
    appApi
      .fetchPointsMeasuringComplex(contractId)
      .then((fetchedMeasures) => {
        const currentMeasures = fetchedMeasures.data.data;
        this.setAccountingPointsMeasures(currentMeasures);
      })
      .catch((reason) => console.error(appApi.onReject(reason)))
      .finally(() => {});
  }

  sendCountersNewValue(
    meteringPointId: number,
    counterId: number,
    indications: Array<Indication>,
  ) {
    return appApi
      .sendCountersNewValue(meteringPointId, counterId, indications)
      .then(() => {
      })
      .catch((reason) => {
        return Promise.reject(appApi.onReject(reason));
      });
  }

  sendAppeal(data: FormData) {
    return appApi
      .sendAppeal(data)
      .then((res) => {
        return res.data;
      })
      .catch((reason) => {
        return Promise.reject(appApi.onReject(reason));
      });
  }

  sendAppealMessage(data: FormData) {
    return appApi
      .sendAppealMessage(data)
      .then((res) => {
        return res.data;
      })
      .catch((reason) => {
        return Promise.reject(appApi.onReject(reason));
      });
  }

  getMassValuesInputTemplateByContract(data: {
    contractId: number;
    download: boolean;
  }) {
    return appApi
      .fetchMassValuesInputTemplateByContract(data)
      .then((axiosRes) => axiosRes)
      .catch((e) => Promise.reject(appApi.onReject(e)));
  }

  getMassValuesInputResultFile(data: {
    fileId: string;
    fileName: string;
    fileUrl: string;
    download: boolean;
  }) {
    return appApi
      .fetchMassValuesInputResultFile(data)
      .then((axiosRes) => axiosRes)
      .catch((e) => Promise.reject(appApi.onReject(e)));
  }

  appealHistoryFileDownload(data: {
    fileUrl: string;
    fileId: string;
    download: boolean;
  }) {
    return appApi
      .fetchAppealHistoryFileDownload(data)
      .then((axiosRes) => axiosRes)
      .catch((e) => Promise.reject(appApi.onReject(e)));
  }

  checkMassValuesInputTemplateByContract(data: FormData) {
    return appApi
      .checkMassValuesInputTemplateByContract(data)
      .then((axiosRes) => axiosRes)
      .catch((e) => Promise.reject(appApi.onReject(e)));
  }

  sendMassValuesInputTemplateByContract(data: FormData) {
    return appApi
      .sendMassValuesInputTemplateByContract(data)
      .then((axiosRes) => axiosRes)
      .catch((e) => Promise.reject(appApi.onReject(e)));
  }

  signIn(credentials: Credentials) {
    return appApi.signIn(credentials).then((user) => {
      const { "x-api-token": xApiToken } = user;

      localStorage.setItem("xApiToken", xApiToken);
      this.setLoggedIn(true);
      this.setUser(user);

      // при удачной авторизации сбрасываем ошибку если была
      this.setErrorMessage();
      this.setShowError(false);

      this.$router.push({
        name: this.passwordChangeRequired ? "accountSettings" : "myContracts",
      });
    });
  }

  enterApp(isFirstRun = false) {
    this.setPreloader(true);

    Promise.all([
      this.getContracts(this.userId),
      isFirstRun ? this.getAppEnvironment() : Promise.resolve(true),
      isFirstRun ? this.getConfig() : Promise.resolve(true),
    ])
      .then(([contracts, _]) => {
        if (!!contracts.length) {
          return this.getContractsBalances(contracts)
            .then(() => contracts)
            .catch((e) => Promise.reject(e))
        } else {
          return contracts
        }
      })
      .then((contracts) => {
        if (contracts.length === 1) {
          return this.chooseContract(contracts[0], true).catch((e) =>
            Promise.reject(e)
          );
        }
      })
      .catch((_) => {
        this.setLoggedIn(false);
      })
      .finally(() => {
        this.setPreloader(false);
      });
  }


  async signUp(newUser: FormData) {
    try {
      return await appApi.signUp(newUser);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  signOut() {
    appApi
      .signOut()
      .then(() => {
        type Mutation = { name: string; value?: any };

        const mutations: Mutation[] = [
          { name: "setLoggedIn", value: false },
          { name: "setUser" },
          { name: "setContracts" },
          { name: "setBalances" },
          { name: "setContract" },
          { name: "setAccountingPoints" },
          { name: "setAccountingPointsPU" },
          { name: "setAccountingPointsMeasures" },
          { name: "setPoint" },
          { name: "setPointHistory" },
          { name: "setPointHourlyFact" },
          { name: "setAskueObjects" },
          { name: "setAsdObjects" },
          { name: "setPointHourlyPlan" },
          { name: "setPointMeasuringComplex" },
          { name: "setPointParams" },
          { name: "setOrganization" },
          { name: "setContacts" },
          { name: "setPaymentAccounts" },
          { name: "setAgents" },
          { name: "setPreloader", value: false },
          { name: "setShowError", value: false },
          { name: "setErrorMessage" },
          { name: "setTypes", value: false },
          { name: "setThemes", value: false },
          { name: "setAppealsByContract", value: false },
          { name: "setAppealHistory", value: false },
          { name: "setCurrentAppealInfo", value: false },
          { name: "setNotificationsByContract" },
          { name: "setUnreadNotificationsByContract" },
        ];

        mutations.forEach((mutation) => {
          const { name, value = null } = mutation;
          this[name](value);
        });
      })
      .catch(console.error)
      .finally(() => {
        const localStorageKeys = ["xApiToken", "personalCompanyAccount"];
        localStorageKeys.forEach((key) => localStorage.removeItem(key));
      });
  }

  async changePassword(passwordsProps: PasswordsAsReqBody): Promise<string[]> {
    try {
      const { message } = await appApi.changePassword(passwordsProps);
      return message;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async changeEmail(email: string) {
    try {
      const { code, data } = await appApi.changeEmail(email);
      return { code , data };
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async confirmEmailChange(newEmailProps: NewEmailProps): Promise<string> {
    try {
      const { message } = await appApi.confirmEmailChange(newEmailProps);
      return message;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async recoveryPassword(recovery: Recovery): Promise<string> {
    try {
      const { message } = await appApi.recoveryPassword(recovery);
      return message;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async recoveryPasswordConfirm(username: string, branch: string, guid: string): Promise<string> {
    try {
      const { message } = await appApi.recoveryPasswordConfirm(username, branch, guid);
      return message;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  getPaymentURL(data: FormData) {
    return appApi
      .getPaymentURL(data)
      .then((res) => {
        return res.data;
      })
      .catch((reason) => {
        return Promise.reject(appApi.onReject(reason));
      });
  }

  getPaymentNoAuthURL(data: FormData) {
    return appApi
      .getPaymentNoAuthURL(data)
      .then((res) => {
        return res.data;
      })
      .catch((reason) => {
        return Promise.reject(appApi.onReject(reason));
      });
  }

  getPaymentFee() {
    return appApi
      .getPaymentFee()
      .then((res) => {
        return res.data;
      })
      .catch((reason) => {
        return Promise.reject(appApi.onReject(reason));
      });
  }

  paymentNoAuthFee(branch: string) {
    return appApi
      .paymentNoAuthFee(branch)
      .then((res) => {
        return res.fee;
      })
      .catch((reason) => {
        return Promise.reject(appApi.onReject(reason));
      });
  }

  downloadPatternRegistrationDocument() {
    return appApi.downloadPatternRegistrationDocument();
  }

  downloadAgreementCalcDocument() {
    return appApi.downloadAgreementCalcDocument();
  }

  downloadMonthlyPlanTemplateDocument() {
    return appApi.downloadMonthlyPlanTemplateDocument();
  }
}

export default AppApiMixin;
