import {required, email, checkbox, digitFloat, amount} from "./field-rules";

export default [
  {
    name: "branch",
    attrs: {
      type: "select",
      label: "Компания",
      required: true, // переопределяется
      items: [], // переопределяется
    },
    rules: [required]
  },
  {
    name: "allowDataHandling",
    attrs: {
      type: "checkbox",
      label: "Я подтверждаю данные платежа",
      required: true
    },
    rules: [required, checkbox]
  },
  {
    name: "amount",
    attrs: {
      type: "currency",
      label: "Сумма платежа",
      default: "0",
      required: true
    },
    rules: [required, amount]
  },
  {
    name: "total",
    attrs: {
      type: "currency",
      label: "Итого к оплате",
      default: "0",
      required: true
    },
    rules: [ required ]
  },
  {
    name: "email",
    attrs: {
      type: "text",
      label: "Email для отправки чека",
      required: true
    },
    rules: [required, email]
  },
  {
    name: "inn",
    attrs: {
      type: "text",
      label: "ИНН организации",
      required: true
    },
    rules: [required, { ...digitFloat, error: "Недопустимый формат" }]
  },
  {
    name: "contract",
    attrs: {
      type: "text",
      label: "Номер договора",
      required: true
    },
    rules: [required]
  }
];
