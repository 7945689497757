var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-card',{attrs:{"title":"Основная информация"}},[_c('card-row-with-email-form',{attrs:{"show-row":_vm.showEmail},on:{"show":function () {
        _vm.showPassword = false;
        _vm.showEmail = !_vm.showEmail;
      },"show-dialog":function ($event) {
        _vm.showEmail = false;
        _vm.$emit($event.name, $event.email);
      }}}),_c('v-divider',{staticClass:"mb-2"}),_c('card-row-with-password-form',{attrs:{"show-row":_vm.showPassword},on:{"show":function () {
        _vm.showEmail = false;
        _vm.showPassword = !_vm.showPassword;
      },"show-notice":function ($event) {
        _vm.showPassword = false;
        _vm.$emit($event.name, $event.message)
      }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }