




















import { Component, Vue } from "vue-property-decorator";
import {mapState} from "vuex";

@Component({
  computed: {
    ...mapState({
      appEnvironment: "appEnvironment",
    }),
    imageName() {
      switch (this.appEnvironment.constants["BRANCH"]) {
        case 'scs': return 'samara.png';
        case 'vocs': return 'tolyatti.png';
        default: return 'logo.svg';
      }
    }
  }})
class CardAsAlert extends Vue {
  data() {
    return {  };
  }
}

export default CardAsAlert;
