















































import {Component, Mixins, Prop} from "vue-property-decorator";
import {AccountingPointIndications} from "@/models/accounting-point";
import SimpleCard from "../SimpleCard.vue";
import NewValueForm from "./CountersNewValueForm.vue";
import ContractIcon from "@/components/ContractIcon.vue";
import SimpleCardWithList from "@/components/SimpleCardWithList.vue";
import {mapGetters} from "vuex";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import { formatDate } from "@/lib/date";

@Component({
  components: {
    SimpleCard,
    ContractIcon,
    NewValueForm,
    SimpleCardWithList
  },
  computed: {
    ...mapGetters("accountingPoints", {
      accountingPoints: "allPoints",
      measures: "allMeasures"
    }),
  },
  data() {
    return {
      puDate: ""
    }
  }
})
class EquipmentListCard extends Mixins(AppApiMixin) {
  @Prop({required: true}) readonly equipment!: AccountingPointIndications;

  get disabled() {
    return this.equipment.запретвводапоказаний != 0;
  }

  equipmentPointAddress(id) {
    return (this.accountingPoints[id] != undefined) ? this.accountingPoints[id].адрес : "";
  }

  get counterData() {
    if (this.measures) {
      // получение даты поверки
      let currentMeasure = this.measures.filter((measure) => measure.$номерЗаписи == this.equipment.счетчикид)[0];
      if (currentMeasure != undefined) {
        this.puDate = formatDate(currentMeasure['датаследующейповерки'], "full");
      } else {
        this.puDate = "-";
      }

      const {заводскойномер, услуга} = this.equipment;

      return [
        {title: 'заводской номер', value: заводскойномер},
        {title: 'услуга', value: услуга},
        {title: 'дата поверки', value: null},
      ]
    }
  }
}

export default EquipmentListCard;
