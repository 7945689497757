import { required } from "./field-rules";

export default [
  {
    name: "branch",
    attrs: {
      type: "select",
      label: "Компания",
      required: true, // переопределяется
      items: [], // переопределяется
    },
    rules: [required]
  },
  {
    name: "username",
    attrs: {
      type: "text",
      label: "Логин",
      required: true
    },
    rules: [required]
  }
];
